// Helper functions to generate dynamic URLs
const generateCityUrl = (city) => {
  const citySlug = city.toLowerCase().replace(/\s+/g, "-");
  return `https://www.vijayhomeservices.com/${citySlug}`;
};

const generateServiceUrl = (serviceSlug1, city) => {
  const citySlug = city.toLowerCase().replace(/\s+/g, "-");
  return `https://www.vijayhomeservices.com/services/${serviceSlug1}-in-${citySlug}`;
};

// Helper function to generate URLs dynamically
const generateSubServiceUrl = (serviceSlug1, city) => {
  const citySlug = city.toLowerCase().replace(/\s+/g, "-");
  const serviceSlugFormatted = serviceSlug1.toLowerCase().replace(/\s+/g, "-");

  return `https://www.vijayhomeservices.com/services/${serviceSlugFormatted}-in-${citySlug}`;
};

// Centralized data structure
const quickLinksData = {
  cities: [
    "Ahmedabad",
    "Bhubaneswar",
    "Chennai",
    "Delhi",
    "Faridabad",
    "Gaziabad",
    "Hyderabad",
    "Kochi",
    "Kolkata",
    "Lucknow",
    "Mumbai",
    "Mysore",
    "Delhi-NCR",
    "Noida",
    "Patna",
    "Pune",
    "Surat",
    "Vadodara",
    "Vizag",
  ].map((city) => ({
    name: city,
    url: generateCityUrl(city),
  })),

  services: [
    { name: "Home Cleaning", slug: "home-cleaning" },
    { name: "Pest Control", slug: "pest-control" },
    { name: "Painting Services", slug: "painting-services" },
  ],

  categories1: [
    {
      name: "painting",
      subcategories1: [
        { name: "Rental Painting", slug: "rental-painting" },
        { name: "1-Day Painting", slug: "1-day-painting" },
        { name: "Interior Painting", slug: "interior-painting" },
        { name: "Exterior Painting", slug: "exterior-painting" },
 
  { name: "Wood Polish", slug: "wood-polish" },
  { name: "Waterproofing", slug: "waterproofing" },
  { name: "Wallpaper", slug: "wallpaper" },
  { name: "Grouting Services", slug: "grouting-services" },
  { name: "Vacant Flat Painting", slug: "vacant-flat-painting" },

  { name: "Painting Services", slug: "painting-services" },

  { name: "Painting Service", slug: "painting-service" },
  { name: "Painting Charges", slug: "painting-charges" },
  { name: "Painting", slug: "painting" },
  { name: "Interior Texture", slug: "interior-texture" },
  { name: "Exterior Texture", slug: "exterior-texture" },
  


  { name: "Painting Charges", slug: "painting-charges" }



      ],
    },
    {
      name: "cleaning",
      subcategories1: [
        { name: "Deep Cleaning", slug: "deep-cleaning" },
        { name: "Bathroom Cleaning", slug: "bathroom-cleaning" },
        { name: "Sofa Cleaning", slug: "sofa-cleaning" },
        { name: "Vacant Home Deep Cleaning", slug: "vacant-home-deep-cleaning"},
        { name: "occupied Home Deep Cleaning", slug: "occupied-home-deep-cleaning" },
        { name: "After Interior Deep Cleaning", slug: "after-interior-deep-cleaning" },
        { name: "Mini Cleaning Services", slug: "mini-cleaning-services" },
        { name: "Kitchen Cleaning", slug: "kitchen-cleaning" },
        { name: "Office Cleaning", slug: "office-cleaning" },
        { name: "Mattress Cleaning", slug: "mattress-cleaning" },
        { name: "Upholstery Cleaning", slug: "upholstery-cleaning" },
        { name: "Floor Cleaning", slug: "floor-cleaning" },
        { name: "Mosaic Floor Polishing", slug: "mosaic-floor-polishing" },
        { name: "Indian Marble Floor Polishing", slug: "indian-marble-floor-polishing" },
        { name: "ItalianMarble Floor Polishing", slug: "italian-marble-floor-polishing" },
        { name: "Granite Floor Polishing", slug: "granite-floor-polishing" },
        { name: "Wood Polishing", slug: "wood-polishing" },
        { name: "Terrace Cleaning", slug: "terrace-cleaning" },
        { name: "Tank And Sump Cleaning", slug: "tank-and-sump-cleaning" },

        { name: "Cleaning Services", slug: "cleaning-services" },
        { name: "Tank Cleaning", slug: "tank-cleaning" },
        { name: "Sump Cleaning", slug: "sump-cleaning" },
        { name: "Villa Cleaning", slug: "villa-cleaning" },
        { name: "Carpet Cleaning", slug: "carpet-cleaning" },
        { name: "Cleaning Service", slug: "cleaning-service" },
        { name: "Cleaning Services", slug: "cleaning-services" },
        { name: "Cleaning", slug: "cleaning" },

        { name: "Terrace Cleaning", slug: "terrace-cleaning" },
        { name: "Cleaning Services", slug: "cleaning-services" },
        { name: "Premium Services", slug: "premium-deep-cleaning---year-end-offer" },
        { name: "New Year Offer", slug: "new-year-offer" },
        { name: "VHS Summer Sale", slug: "vhs-summer-sale-!!" }


      ],
    },
    {
      name: "PestControl",
      subcategories1: [
        { name: "General Pest Control", slug: "general-pest-control" },
  { name: "Cockroach Control", slug: "cockroach-control" },
  { name: "Mosquitoes Control", slug: "mosquitoes-control" },
  { name: "Termite Control", slug: "termite-control" },
  { name: "Woodborer Control", slug: "woodborer-control" },
  { name: "Commercial Pest Control", slug: "commercial-pest-control" },
  { name: "Bedbugs Control", slug: "bedbugs-control" },
  { name: "Pest Control", slug: "pest-control" },
      ],
    },

    {
      name: "Appliance Services",
      subcategories1: [
        { name: "Washing Machine Repairing", slug: "washing-machine-repairing" },
        { name: "AC Repairing", slug: "ac-repairing" },
        { name: "Refrigerator Repairing", slug: "refrigerator-repairing" },
        { name: "Split AC Service", slug: "split-ac-service" },
        { name: "Window AC Service", slug: "window-ac-service" },
        { name: "Geyser Repairing", slug: "geyser-repairing" },
      ],
    },

    {
      name: "Home Repair Services",
      subcategories1: [
        { name: "Plumbing Work", slug: "plumbing-work" },
        { name: "Electrical Work", slug: "electrical-work" },
        { name: "Carpenter Work", slug: "carpenter-work" },
        { name: "Bird Netting", slug: "bird-netting" },
      ],
    },
    {
      name: "Corporate Facility Management",
      subcategories1: [
        { name: "Hospital Facility Management", slug: "hospital-facility-management" },
        { name: "Housekeeping Service", slug: "housekeeping-service" },
      ],
    }
        
    
  ],
  
};

export { quickLinksData, generateServiceUrl, generateSubServiceUrl };
